@import "~antd/dist/antd.css";
@import "./general.css";

.auth-form {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 300px;
    width: 100%;
}

.antd-card-with-shadow {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
        0 5px 12px 4px rgb(0 0 0 / 9%);
}

.layout-spin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
}

.page-object-horizontal-margin {
    margin-left: 12px;
    margin-right: 12px;
}

.ant-table {
    overflow-x: auto;
}

[class="ant-table-cell ant-table-cell-row-hover"] {
    background-color: transparent !important;
}

tr > * {
    border-color: #a6a6a2 !important;
}

.ant-table-container {
    border-left-color: #a6a6a2 !important;
}

table {
    border-top-color: #a6a6a2 !important;
}

.ant-table-thead > tr > th {
    background-color: #d9d9d9 !important;
}

.ant-table-column-sorter,
.ant-table-filter-trigger {
    color: #ffffff;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ant-card-head-title {
    flex: none;
}

.ant-card-extra {
    display: flex;
    flex: 1;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: calc(100vw - 100px);
}

.row-dragging td {
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.cs-conversation__unread-dot {
    align-self: flex-start !important;
    background: red !important;
    box-shadow: none !important;
    width: 14px !important;
    height: 14px !important;
    border-radius: 7px !important;
}

.ant-layout-header {
    display: flex;
    align-items: center;
}

.ant-layout-header > img {
    height: 50px;
    margin-right: 10px;
    width: 50px;
}

.tracking-map-placemark {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 42px;
    min-height: 42px;
}

.tracking-map-placemark-icon {
    width: 42px;
    height: 42px;
    background-color: #ffffff;
    border-radius: 50%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 7.5pt;
}

.tracking-map-placemark-bottom-span {
    background-color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #000;
    min-width: 80px;
    margin-top: 5px;
    font-size: 8pt;
}

.tracking-map-placemark-left-span {
    background-color: #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #000;
    min-width: 120px;
    position: absolute;
    left: 47px;
    top: 0px;
    z-index: 1000;
    font-size: 8pt;
}

.master-placemark-icon {
    border: 3px solid #26a637;
}

.order-placemark-icon {
    border: 3px solid #1890ff;
}

.ant-menu-overflow {
    flex: 1;
}

.card-extra-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
}

.card-extra-layout-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
}

.card-extra-layout-content > :first-child {
    flex-wrap: wrap;
}

.table-search-input-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    /* margin-top: 10px; */
}

.table-search-input {
    max-width: 250px;
}

.chat-system-message {
    justify-content: center;
    max-width: 100% !important;
}

.chat-system-message .cs-message__content {
    border-radius: 0.7em !important;
    font-size: 12pt;
    font-weight: 600;
}

.menu-sub-user-block {
    background-color: #1890ff !important;
    height: 64px !important;
}

.button-vertical-content {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}

.button-vertical-content > span {
    margin-left: 0 !important;
}