.d-flex {
    display: flex !important;
}

.flex-1 {
    flex: 1 !important;
    flex-grow: 1 !important;
}

.flex-content-centered {
    align-items: center !important;
    justify-content: center !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.text-center {
    text-align: center;
}

.w-100 {
    width: 100% !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}