html {
    display: flex;
    flex: 1;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    display: flex;
    flex: 1;
    flex-grow: 1;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    display: flex;
    flex: 1;
    flex-grow: 1;
}

.table-actions-column {
    width: 50px;
    text-align: center;
}

.map-placemark-icon {
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 3px solid #26a637;
    object-fit: cover;
}

.d-none {
    display: none !important;
}

.input-arrows-hidden::-webkit-outer-spin-button,
.input-arrows-hidden::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-arrows-hidden {
    -moz-appearance: textfield;
}
